import React from "react";

import Card from 'react-bootstrap/Card';
import { Image } from "react-bootstrap";

import './Testimonial.scss';

function Testimonial(props) {
    return (
        <Card className="testimonial-card text-center p-0">
            <Card.Header>
                <Image src={props.img} alt="Testimonial Customer Image" />
                <div>
                    <Card.Title>{props.name}</Card.Title>
                    {Array.from({ length: props.stars }, (_, index) => (
                        <Image key={index} src="/images/testimonials/star.png" alt="Star" className="d-inline" />
                    ))}
                </div>
            </Card.Header>
            <Card.Body>
                <Image src="images/testimonials/quote.png" alt="Testimonial Quote Image" />
                <Card.Text>{props.text}</Card.Text>
            </Card.Body>
      </Card>
    );
}

export default Testimonial;
