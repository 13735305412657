import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faUser, faArrowRight, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

import { Container, Row, Col, Form, InputGroup, Alert } from 'react-bootstrap';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';

import axios from 'axios';

import './quote.scss';

function Quote() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  });

  const [errors, setErrors] = useState({});
  const [showAlert, setShowAlert] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateForm = () => {
    const newErrors = {};

    // Validate name (required)
    if (!formData.name.trim()) {
      newErrors.name = 'Name is required';
    }

    // Validate email format
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (!formData.email.trim()) {
      newErrors.email = 'Email is required';
    } else {
      if (!emailRegex.test(formData.email)) {
        newErrors.email = 'Invalid email address';
      }
    }

    // Validate phone (required)
    if (!formData.phone.trim()) {
      newErrors.phone = 'Phone is required';
    }

    // Validate message (required)
    if (!formData.message.trim()) {
      newErrors.message = 'Message is required';
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const apiUrl = 'http://localhost/my_works/pixzarloop/px0121-rathnayakaplantsnursery/API/get_quote.php';

  const displayAlert = () => {
    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false);
    }, 5000); // Hide the alert after 5 seconds (adjust the timing as needed)
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateForm()) {
      setIsSubmitting(true); // Set the form submission state to true
      // Send the email using Axios
      axios.post(apiUrl, formData, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        if (response.data.status === "success") {
          console.log(response.data.message);
          // Display the success alert
          displayAlert();
          // Clear the form fields
          setFormData({
            name: '',
            email: '',
            phone: '',
            message: '',
          });
        } else {
          console.error(response.data.message);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      })
      .finally(() => {
        setIsSubmitting(false); // Set the form submission state back to false
      });
    }
  };

  return (
    <>
      {/* Banner section */}
      <section id="banner">
        <Image src="/images/contact-banner.webp" alt="Quote Banner" className="w-100" />
        <div className="banner-content">
          <h1>GET A Quote</h1>
        </div>
      </section>
      
      {/* Quote info section */}
      <section id="quoteInfoSection">
        <Container>
          <Row className="align-items-center">
            <Col md={6}>
              <Row>                
                <Col className="p-0">
                  <Image src="/images/quote-img.webp" alt="Quote Image" className="img-fluid"/>
                </Col>
              </Row>
            </Col>
            <Col md={6}>
              <h4 className="mb-0 mt-4 mt-md-0 d-block d-md-block">Get Your Green Quote</h4>
              <p className="d-block d-md-block">Customize your garden with tailored planting solutions.</p>

              <Row>
                <Col xs={12} md={10} lg={8}>
                  {/* Success message alert */}
                  {showAlert && (
                    <Alert variant="success" className="mt-3">
                      Message sent successfully!
                    </Alert>
                  )}
                  <Form>
                    <Form.Group className="mb-3" controlId="name">
                      <Form.Label>Name<span className="text-danger">*</span></Form.Label>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          name="name"
                          value={formData.name}
                          onChange={handleChange}
                          placeholder="Adam Smith"
                        />
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faUser} />
                        </InputGroup.Text>
                      </InputGroup>
                      {errors.name && <div className="text-danger">{errors.name}</div>}
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="email">
                      <Form.Label>Email<span className="text-danger">*</span></Form.Label>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                          placeholder="example@gmail.com"
                        />
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faEnvelope} />
                        </InputGroup.Text>
                      </InputGroup>
                      {errors.email && <div className="text-danger">{errors.email}</div>}
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="phone">
                      <Form.Label>Phone<span className="text-danger">*</span></Form.Label>
                      <InputGroup>
                        <Form.Control
                          type="tel"
                          name="phone"
                          value={formData.phone}
                          onChange={handleChange}
                          placeholder="Enter your whatsapp number"
                        />
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faWhatsapp} />
                        </InputGroup.Text>
                      </InputGroup>
                      {errors.phone && <div className="text-danger">{errors.phone}</div>}
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="message">
                      <Form.Label>Message<span className="text-danger">*</span></Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        placeholder="Enter your interested product name/message"
                      />
                      {errors.message && <div className="text-danger">{errors.message}</div>}
                    </Form.Group>

                    {/* Buttons for submitting */}
                    <div className="d-flex justify-content-center justify-content-md-start mt-3">
                      <Button
                        type="submit"
                        className={`w-50 ${isSubmitting ? 'd-none' : ''}`}
                        onClick={handleSubmit}
                      >
                        Get Quote <FontAwesomeIcon icon={faArrowRight} />
                      </Button>
                      <Button
                        type="submit"
                        className={`w-50 ${isSubmitting ? '' : 'd-none'}`}
                        disabled
                      >
                        Sending <FontAwesomeIcon icon={faSpinner} spin/>
                      </Button>
                    </div>
                  </Form>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default Quote;