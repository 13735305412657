import React from "react";
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faWhatsapp, faInstagram } from '@fortawesome/free-brands-svg-icons';

import { Container, Row, Col } from 'react-bootstrap';
import Image from 'react-bootstrap/Image';

import './Footer.scss';

function Footer() {
    return (
        <footer id="footer">
            <Container>
                <Row>
                    <Col xs={12} lg={6} className="d-flex align-items-center footer-left">
                        <div>
                            <Image src="/images/footer-logo.png" alt="Logo" style={{ width: "50px" }} />
                        </div>
                        <div className="mx-4">
                            <p className="m-0">Copyright © 2023 Pixzarloop.</p>
                            <p className="m-0">All rights reserved</p>
                        </div>
                    </Col>
                    <Col xs={12} lg={6} className="footer-right">
                        <div className="nav-links">
                            <ul>
                                <li>
                                    <Link to="/about" className="nav-link">About Us</Link>
                                </li>
                                <li>
                                    <Link to="/contact" className="nav-link">Contact Us</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="social-links">
                            <ul>
                                <li>
                                <a href="https://api.whatsapp.com/send?phone=+94717296004&text=Hello," rel="noreferrer" target="_blank" style={{ textDecoration: 'none' }}><FontAwesomeIcon icon={faWhatsapp} /></a>
                                </li>
                                <li>
                                    <Link to="https://facebook.com/profile.php?id=100057402612085" target="_blank"><FontAwesomeIcon icon={faFacebookF} /></Link>
                                </li>
                                <li>
                                    <Link to="https://www.instagram.com/rathnayake_flower_plants" target="_blank"><FontAwesomeIcon icon={faInstagram} /></Link>
                                </li>
                            </ul>
                        </div>
                    </Col>
                </Row>
            </Container>
        </footer>
    );
}

export default Footer;
