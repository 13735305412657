import React from "react";

import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image';

import './ServiceCard.scss';

function ServiceCard(props) {
    return (
        <Card className="service-card text-center p-0">
            <Card.Body>
                <div className="service-icon">
                    <Image src={props.img} alt="Service Icon" />
                </div>
                <Card.Title>{props.title1} <br></br> {props.title2}</Card.Title>
                <Card.Text>{props.text}</Card.Text>
            </Card.Body>
      </Card>
    );
}

export default ServiceCard;