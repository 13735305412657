import React from 'react';

import { Container, Row, Col } from 'react-bootstrap';
import Image from 'react-bootstrap/Image';

import './about.scss';

function About() {
  return (
    <>
      {/* Banner section */}
      <section id="banner">
        <Image src="/images/about-banner.webp" alt="About Banner" className="w-100" />
        <div className="banner-content">
          <h1>About Us</h1>
        </div>
      </section>

      {/* Intro section */}
      <section id="introSection">
        <Container>
          <Row className="d-block d-md-none">
            <h4 className="mb-1 text-center" style={{fontSize: "20px"}}>Blossoming Together</h4>
            <h6 className="m-0 text-center" style={{fontSize: "16px"}}>Embrace Nature with Rathnayaka Plant Nursery</h6>
          </Row>
          <Row className="align-items-center">
            <Col md={5}>
              <Row className="justify-content-center align-items-center my-4 my-md-0">                
                <div className="col-4 p-0">
                  <Image src="/images/about/about-1.jpg" alt="About Image" className="w-100 p-1" />
                  <Image src="/images/about/about-4.jpg" alt="About Image" className="w-100 p-1" />
                </div>

                <div className="col-4 p-0">
                  <Image src="/images/about/about-3.jpg" alt="About Image" className="w-100 p-1" />
                  <Image src="/images/about/about-2.jpg" alt="About Image" className="w-100 p-1" />
                </div>
              </Row>
            </Col>
            <Col md={7}>
              <h4 className="mb-1 d-none d-md-block">Blossoming Together</h4>
              <h6 className="mb-4 d-none d-md-block">Embrace Nature with Rathnayaka Plant Nursery</h6>
              <p>Welcome to Rathnayaka Plant Nursery, where a passion for greenery meets over 16 years of experience in nurturing nature's wonders. As a renowned and trusted name in the industry, we take pride in offering a diverse range of top-quality fruits, vegetables, and indoor plants, carefully curated to bring the beauty of nature right to your doorstep. </p>
              <p>Our commitment to excellence goes hand-in-hand with our friendly and personalized service, ensuring that every visit to our nursery leaves you inspired and satisfied. At Rathnayaka, we are more than just a team of experienced agriculture and planting professionals – we are enthusiastic gardeners and plant lovers ourselves, dedicated to sharing our expertise and helping you create your own green oasis.</p>
              <p>Join us on this green journey and let's grow dreams together.</p>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default About;