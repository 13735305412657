import React from 'react';

import ServiceCard from '../../components/serviceCard/ServiceCard';
import Testimonial from '../../components/testimonial/Testimonial';

import { Container, Row, Col } from 'react-bootstrap';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';

import Slider from "react-slick";
import { Slide } from "react-awesome-reveal";

import './home.scss';
import { Link } from 'react-router-dom';

function Home() {
  // Brand carousel settings
  var brandCarouselSettings = {
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          autoplay: true,
          autoplaySpeed: 3000,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          autoplay: true,
          autoplaySpeed: 3000,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplaySpeed: 3000,
        }
      }
    ]
  };

    // Testimonial carousel settings
    var testimonialCarouselSettings = {
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 3000,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            autoplay: true,
            autoplaySpeed: 3000,
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            autoplay: true,
            autoplaySpeed: 3000,
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplaySpeed: 3000,
          }
        }
      ]
    };

  return (
    <>
      {/* Home banner section */}
      <section id="homeBanner">
        <Image src="/images/home-banner.webp" alt="Home Banner" className="w-100" />
        <div className="banner-content">
          <h1>Rathnayaka Plants Nursery</h1>
          <p>The best place for your home garden</p>
          <div className="d-flex justify-content-center">
            <Link to="/contact">
              <Button variant="outline-light mx-2">CONTACT US</Button>
            </Link>
            <Link to="/quote">
              <Button variant="outline-light mx-2" className="d-none d-md-block">GET A QUOTE</Button>
            </Link>
          </div>
        </div>
      </section>
      
      {/* Service cards section */}
      <section id="serviceCards">
        <Container>
          <Row className="justify-content-center justify-content-md-around">
            <Col md={4} lg={3} className="mb-4">
              <ServiceCard img="/images/service-icons/hands-helping.png" title1="FRIENDLY" title2="SERVICE" text="Our team of plant enthusiasts is eager to assist you." />
            </Col>
            <Col md={4} lg={3} className="mb-4">
              <ServiceCard img="/images/service-icons/tree.png" title1="WIDE" title2="VARIETY" text="Explore a diverse selection of plants for your garden." />
            </Col>
            <Col md={4} lg={3} className="mb-4">
              <ServiceCard img="/images/service-icons/hand-holding-usd.png" title1="BEST" title2="PRICE" text="Explore unbeatable prices with exceptional quality." />
            </Col>
          </Row>
        </Container>
      </section>

      {/* Brands section */}
      <section id="brandSection">
        <Container>
          <h5 className="m-0">Trusted high quality brands</h5>
          <Slider {...brandCarouselSettings}>
            <div className="d-flex justify-content-center">
              <Image src="/images/brands/cic.jpg" alt="Brand Logo" />
            </div>
            <div className="d-flex justify-content-center">
              <Image src="/images/brands/baurs.jpg" alt="Brand Logo" />
            </div>
            <div className="d-flex justify-content-center">
              <Image src="/images/brands/dsi.jpg" alt="Brand Logo" />
            </div>
            <div className="d-flex justify-content-center">
              <Image src="/images/brands/heyleys.jpg" alt="Brand Logo" />
            </div>
            <div className="d-flex justify-content-center">
              <Image src="/images/brands/harcros.jpg" alt="Brand Logo" />
            </div>
            <div className="d-flex justify-content-center">
              <Image src="/images/brands/asis.jpg" alt="Brand Logo" />
            </div>
            <div className="d-flex justify-content-center">
              <Image src="/images/brands/lankem.jpg" alt="Brand Logo" />
            </div>
            <div className="d-flex justify-content-center">
              <Image src="/images/brands/phoenix.jpg" alt="Brand Logo" />
            </div>
            <div className="d-flex justify-content-center">
              <Image src="/images/brands/upl.jpg" alt="Brand Logo" />
            </div>
          </Slider>
        </Container>
      </section>

      {/* Products section */}
      <section id="productSection">
        <Container>
          <h5 className="mb-0 mt-5 text-center">Our Products</h5>

          {/* Cactus */}
          <Row className="productRow">
            <Col md={5}>
              <Slide>
                <Row className="justify-content-center justify-content-md-start align-items-center">                
                  <div className="col-4 p-0">
                    <Image src="/images/products/cactus/cactus-1.jpg" alt="Cactus Image" className="w-100 p-1" />
                    <Image src="/images/products/cactus/cactus-2.jpg" alt="Cactus Image" className="w-100 p-1" />
                  </div>

                  <div className="col-4 p-0">
                    <Image src="/images/products/cactus/cactus-4.jpg" alt="Cactus Image" className="w-100 p-1" />
                    <Image src="/images/products/cactus/cactus-3.jpg" alt="Cactus Image" className="w-100 p-1" />
                  </div>
                </Row>
              </Slide>
            </Col>
            <Col md={7}>
              <Slide direction="right">
                <h4>Cactus</h4>
                <p>Our Cactus Collection offers a variety of types, from vibrant Moon Cactus to elegant Gymnocalycium and resilient Hybrid Aloes. Discover the beauty of Variegated Gymnocalycium or the enigmatic Astrophytum. Whether you're a seasoned enthusiast or a curious beginner, our expertly nurtured cacti will add charm to your living spaces.</p>
              </Slide>
            </Col>
          </Row>

          {/* Indoor Plants */}
          <Row className="productRow">
            <Col md={7}>
              <Slide>
                <h4>Indoor Plants</h4>
                <p>Explore a delightful variety of greenery for your home. From graceful Pachira to iconic Monstera Deliciosa, we have something for everyone. Bring nature's charm indoors effortlessly with our carefully nurtured plants. Discover your perfect indoor companion today! Add a splash of color to any room effortlessly in various indoor conditions. For those seeking a touch of nostalgia, the classical indoor plants evokes memories of homes adorned with greenery.</p>
              </Slide>
            </Col>
            <Col md={5}>
              <Slide direction="right">
                <Row className="justify-content-center justify-content-md-end align-items-center">                
                  <div className="col-4 p-0">
                    <Image src="/images/products/indoor-plants/indoor-plants-2.jpg" alt="Cactus Image" className="w-100 p-1" />
                    <Image src="/images/products/indoor-plants/indoor-plants-3.jpg" alt="Cactus Image" className="w-100 p-1" />
                  </div>

                  <div className="col-4 p-0">
                    <Image src="/images/products/indoor-plants/indoor-plants-5.jpg" alt="Cactus Image" className="w-100 p-1" />
                    <Image src="/images/products/indoor-plants/indoor-plants-1.jpg" alt="Cactus Image" className="w-100 p-1" />
                    <Image src="/images/products/indoor-plants/indoor-plants-4.jpg" alt="Cactus Image" className="w-100 p-1" />
                  </div>
                </Row>
              </Slide>
            </Col>
          </Row>

          {/* Outdoor Plants */}
          <Row className="productRow">
            <Col md={5}>
              <Slide>
                <Row className="justify-content-center justify-content-md-start align-items-center">                
                  <div className="col-4 p-0">
                    <Image src="/images/products/outdoor-plants/outdoor-plants-1.jpg" alt="Outdoor Plants Image" className="w-100 p-1" />
                    <Image src="/images/products/outdoor-plants/outdoor-plants-4.jpg" alt="Outdoor Plants Image" className="w-100 p-1" />
                  </div>

                  <div className="col-4 p-0">
                    <Image src="/images/products/outdoor-plants/outdoor-plants-3.jpg" alt="Outdoor Plants Image" className="w-100 p-1" />
                    <Image src="/images/products/outdoor-plants/outdoor-plants-2.jpg" alt="Outdoor Plants Image" className="w-100 p-1" />
                  </div>
                </Row>
              </Slide>
            </Col>
            <Col md={7}>
              <Slide direction="right">
                <h4>Outdoor Plants</h4>
                <p>Choose from a wide selection of flowering plants, majestic large trees, and enchanting forestland trees. Embrace the beauty of lilies and the healing power of medicinal plants. Add a touch of greenery with foliage plants or splashes of color with bougainvillea, petunia, dianthus, and Mandevilla. Create a captivating outdoor oasis with our carefully curated collection.</p>
              </Slide>
            </Col>
          </Row>

          {/* Fertilizers */}
          <Row className="productRow">
            <Col md={7}>
              <Slide>
                <h4>Fertilizers</h4>
                <p>Home gardeners here have access to a diverse range of fertilizers, both organic and synthetic, tailored to suit various plant needs. From traditional compost and cow manure to innovative bio-fertilizers, these products ensure the soil remains nutrient-rich and fertile.</p>
              </Slide>
            </Col>
            <Col md={5}>
              <Slide direction="right">
                <Row className="justify-content-center justify-content-md-end align-items-center">                
                  <div className="col-4 p-0">
                    <Image src="/images/products/fertilizers/fertilizers-1.jpg" alt="Fertilizers Image" className="w-100 p-1" />
                    <Image src="/images/products/fertilizers/fertilizers-2.jpg" alt="Fertilizers Image" className="w-100 p-1" />
                  </div>

                  <div className="col-4 p-0">
                    <Image src="/images/products/fertilizers/fertilizers-3.jpg" alt="Fertilizers Image" className="w-100 p-1" />
                    <Image src="/images/products/fertilizers/fertilizers-4.jpg" alt="Fertilizers Image" className="w-100 p-1" />
                  </div>
                </Row>
              </Slide>
            </Col>
          </Row>

          {/* Tools & Equipments */}
          <Row className="productRow">
            <Col md={5}>
              <Slide>
                <Row className="justify-content-center justify-content-md-start align-items-center">                
                  <div className="col-4 p-0">
                    <Image src="/images/products/tools-and-equipments/tools-and-equipments-1.jpg" alt="Tools & Equipments Image" className="w-100 p-1" />
                    <Image src="/images/products/tools-and-equipments/tools-and-equipments-4.jpg" alt="Tools & Equipments Image" className="w-100 p-1" />
                  </div>

                  <div className="col-4 p-0">
                    <Image src="/images/products/tools-and-equipments/tools-and-equipments-2.jpg" alt="Tools & Equipments Image" className="w-100 p-1" />
                    <Image src="/images/products/tools-and-equipments/tools-and-equipments-3.jpg" alt="Tools & Equipments Image" className="w-100 p-1" />
                  </div>
                </Row>
              </Slide>
            </Col>
            <Col md={7}>
              <Slide direction="right">
                <h4>Tools & Equipment</h4>
                <p>Whether you're an avid gardener or a novice looking to explore the joys of cultivating your own produce, a wide array of home garden equipment is available to cater to every need. From planting pots, sturdy hand tools for meticulous planting and pruning to modern irrigation systems that optimize water usage, we offer a comprehensive range of products.</p>
              </Slide>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Testimonials section */}
      <section id="testimonialSection">
        <Container>
          <h5 className="mb-4 mt-4 text-center">What Our Customers Say</h5>
          <Slider {...testimonialCarouselSettings}>
            <div className="d-flex justify-content-center">
              <Testimonial img="/images/testimonials/sameena-ismail.png" name="Sameena Ismail" text="Ideal place to buy varieties of plants to make your garden look better and beautiful. Also plant seeds and agricultural products can be purchased too. Customer service was good. Prices are reasonable." stars={5}/>
            </div>
            <div className="d-flex justify-content-center">
              <Testimonial img="/images/testimonials/damitha-prabash.png" name="Damitha Prabash" text="Can buy various types of plants, flowers, pots, fertilizers, etc., and good Service. Recommended." stars={5}/>
            </div>
            <div className="d-flex justify-content-center">
              <Testimonial img="/images/testimonials/kithsiri-jayakody.png" name="Kithsiri Jayakody" text="Ideal place to buy varieties of plants to make your garden look better and beautiful. Also plant seeds and agricultural products can be purchased too. Customer service was good. Prices are reasonable." stars={4}/>
            </div>
          </Slider>
        </Container>
      </section>
    </>
  );
}

export default Home;
