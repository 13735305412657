import React from "react";

import { Link } from "react-router-dom";

import Card from 'react-bootstrap/Card';

import './ProductCard.scss';

function ProductCard(props) {
    return (
        <Card className="product-card text-center p-0">
            <Card.Body>
                <Card.Img variant="center" src={props.img} className="w-100" />
                <div className="product-overlay">
                    <Link to="/quote">Get a Quote</Link>
                </div>
            </Card.Body>
            <Card.Footer>
                <Card.Text className="py-1">
                    {props.title}
                </Card.Text>
            </Card.Footer>
      </Card>
    );
}

export default ProductCard;