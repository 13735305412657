import React from 'react';

import ProductCard from '../../components/productCard/ProductCard';

import { Container, Row, Col } from 'react-bootstrap';
import Image from 'react-bootstrap/Image';

import './products.scss';

function Products() {
  const products = [
    { img: "/images/popular-products/moon-cactus.jpg", title: "Moon Cactus" },
    { img: "/images/popular-products/gymnocalysium.jpg", title: "Gymnocalysium" },
    { img: "/images/popular-products/hybrid-aloes.jpg", title: "Hybrid Aloes" },
    { img: "/images/popular-products/lotus.jpg", title: "Lotus" },
    { img: "/images/popular-products/gymnocalysium.jpg", title: "Gymnocalysium" },
    { img: "/images/popular-products/moon-cactus.jpg", title: "Moon Cactus" },
    { img: "/images/popular-products/lotus.jpg", title: "Lotus" },
    { img: "/images/popular-products/hybrid-aloes.jpg", title: "Hybrid Aloes" },
  ];
  
  const productCards = products.map((product, index) => (
    <Col xs={6} md={3} className="mb-4" key={index}>
      <ProductCard img={product.img} title={product.title} />
    </Col>
  ));
  
  return (
    <>
      {/* Banner section */}
      <section id="banner">
        <Image src="/images/products-banner.webp" alt="Products Banner" className="w-100" />
        <div className="banner-content">
          <h1>Popular Products</h1>
        </div>
      </section>

      {/* Product cards section */}
      <section id="productCards">
        <Container>
          <Row className="justify-content-center justify-content-md-around">
            {productCards}
          </Row>
        </Container>
      </section>
    </>
  );
}

export default Products;