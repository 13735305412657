import React, { useState } from "react";

import { Link, NavLink } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from "@fortawesome/free-solid-svg-icons";

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Image from 'react-bootstrap/Image';

import './Header.scss';

function Header() {
    const [showOffcanvas, setShowOffcanvas] = useState(false);

    const closeOffcanvas = () => {
        setShowOffcanvas(false);
    };

    return (
        <header id="header">
            <Navbar key="md" expand="md">
                <Container>
                    <Link to="/" className="navbar-brand d-flex align-items-center">
                        <Image src="/images/nav-logo.png" alt="Logo"/>
                    </Link>
                    <Navbar.Toggle 
                        aria-controls="offcanvasNavbar-expand-md" 
                        className="border-0 text-light"
                        onClick={() => setShowOffcanvas(!showOffcanvas)}
                    >
                        <FontAwesomeIcon icon={faBars} />
                    </Navbar.Toggle>
                    <Navbar.Offcanvas id={`offcanvasNavbar-expand-md`} aria-labelledby={`offcanvasNavbarLabel-expand-md`} placement="end"  show={showOffcanvas} onHide={closeOffcanvas}>
                        <Offcanvas.Header closeButton>
                            <Image src="/images/nav-logo.png" style={{ width: "80%" }} alt="Logo"/>
                            {/* <Offcanvas.Title id={`offcanvasNavbarLabel-expand-md`}>Rathnayaka Plants Nursery</Offcanvas.Title> */}
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            {/*Mobile Nav*/}
                            <Nav className="justify-content-end flex-grow-1 d-flex d-md-none">
                                <NavLink to="/" className="nav-link" activeclassname="active-link" onClick={() => setShowOffcanvas(!showOffcanvas)}>HOME</NavLink>
                                <NavLink to="/products" className="nav-link" activeclassname="active-link" onClick={() => setShowOffcanvas(!showOffcanvas)}>POPULAR PRODUCTS</NavLink>
                                <NavLink to="/about" className="nav-link" activeclassname="active-link" onClick={() => setShowOffcanvas(!showOffcanvas)}>ABOUT US</NavLink>
                                <NavLink to="/contact" className="nav-link" activeclassname="active-link" onClick={() => setShowOffcanvas(!showOffcanvas)}>CONTACT US</NavLink>
                            </Nav>

                            {/*Desktop Nav*/}
                            <Nav className="justify-content-end flex-grow-1 d-none d-md-flex">
                                <NavLink to="/" className="nav-link" activeclassname="active-link">HOME</NavLink>
                                <NavLink to="/products" className="nav-link" activeclassname="active-link">POPULAR PRODUCTS</NavLink>
                                <NavLink to="/about" className="nav-link" activeclassname="active-link">ABOUT US</NavLink>
                                <NavLink to="/contact" className="nav-link" activeclassname="active-link">CONTACT US</NavLink>
                            </Nav>
                        </Offcanvas.Body>
                    </Navbar.Offcanvas>
                </Container>
            </Navbar>
        </header>
    );
}

export default Header;